<script src="js/flexible.js"></script>
<script src="js/jquery.js"></script>
<script src="js/echarts.min.js"></script>
<script src="js/index.js"></script>
<script src="js/china.js"></script>
<script src="js/myMap.js"></script>

<template>
  <body id="bodytest">
    <header>
      <h1>慢性病五维动态数字治疗平台</h1>
      <div class="showTime" style="right: 14rem;">首页</div>
      <div class="showTime" style="right: 11rem;">动态健康档案建立</div>
      <div class="showTime" style="right: 8.5rem;">动态健康数据采集</div>
      <div class="showTime" style="right: 6rem;">动态健康数据达标</div>
      <div class="showTime" style="right: 3.5rem;">动态高危风险评估</div>
      <div class="showTime" style="right: 1rem;">动态健康方案调整</div>
    </header>
    <div style="width: 100%; height: 0.5rem;">
      <!-- <div style="float: left;  font-size: 0.4rem; color: rgb(255, 255, 255); line-height: 0.35rem; left: 12%; position: relative;">大白健康星球</div> -->
      <div style="float: left;  font-size: 0.4rem; color: rgb(255, 255, 255); line-height: 0.35rem; left: 12%; position: relative;">{{channel}}</div>
    </div>
    <section class="mainbox">
      <div class="column">
      </div>
      <div class="column">
        <div class="panel bar" style="height: 3.5rem;">
          <h2>动态数字达标</h2>
          <div style="width: 100%; height: 0.4rem;"></div>
          <div style="width: 100%; height: 1.6rem;">
            <!-- <el-progress type="circle" :percentage="70" status="warning" :text-inside="false" :show-text="false" style="left:8%; width: 1.6rem;"></el-progress>
            <el-progress type="circle" :percentage="70" status="success" :text-inside="false" :show-text="false" style="left:16%; width: 1.6rem;"></el-progress>
            <el-progress type="circle" :percentage="75" :text-inside="false" :show-text="false" style="left:24%; width: 1.6rem;"></el-progress> -->
            <img src="./images/01.png" style="width: 1.7rem; left: 0.4rem; bottom: 0.2rem; position: relative;">
            <img src="./images/02.png" style="width: 1.7rem; left: 0.85rem; bottom: 0.2rem; position: relative;">
            <img src="./images/03.png" style="width: 1.7rem; left: 1.3rem; bottom: 0.2rem; position: relative;">
          </div>
          <div style="width: 100%;">
            <!-- [list.annualUser?list.annualUser+140:140, list.basicUser?list.basicUser+100:100, list.experienceUser?list.experienceUser+60:60, list.vipUser?list.vipUser+40:40, list.ordinaryUser?list.ordinaryUser+30:30]; -->
            <!-- <div style="float: left;  font-size: 0.25rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 1rem; bottom: 1.1rem; position: relative;">{{list.annualUser?list.annualUser:0 + list.basicUser?list.basicUser:0 + list.experienceUser?list.experienceUser:0 + list.vipUser?list.vipUser:0 + list.ordinaryUser?list.ordinaryUser:0 + 280}}人</div> -->
            <div style="float: left;  font-size: 0.25rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 1rem; bottom: 1.1rem; position: relative; width: 0.7rem;">{{sum}}人</div>
            <div style="float: left;  font-size: 0.25rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 2.45rem; bottom: 1.1rem; position: relative;">70%</div>
            <div style="float: left;  font-size: 0.25rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 4.1rem; bottom: 1.1rem; position: relative;">280次</div>
          </div>
          <div style="width: 100%; height: 0.1rem;"></div>
          <div style="width: 100%;">
            <div style="float: left;  font-size: 0.2rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; right: 1.25rem; position: relative;">慢性病患总人数</div>
            <div style="float: left;  font-size: 0.2rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; right: 0.525rem; position: relative;">慢性病总达标率</div>
            <div style="float: left;  font-size: 0.2rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; right: 0.05rem; position: relative;">慢性病数据预警总次数</div>
          </div>
          <div class="panel-footer"></div>
        </div>
        <div class="panel line" style="height: 1.5rem;">
          <h2>高危风险及数据预警列表</h2>
          <div style="width: 100%; height: 0.2rem;"></div>
          <div style="width: 100%; height: 1rem;">
            <div style="float: left;  font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 5%; position: relative;">高危预警</div>
            <div style="float: left;  font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 7%; position: relative;" >：vip会员王先生显示血压异常,请及时咨询问诊......</div>
            <i class="el-icon-arrow-right" style="float: left;  font-size: 0.21rem; color: rgba(102, 105, 233, 0.7); line-height: 0.35rem; left: 8%; position: relative;"></i>
            <i class="el-icon-arrow-right" style="float: left;  font-size: 0.21rem; color: rgba(65, 156, 241, 0.7); line-height: 0.35rem; left: 7%; position: relative;"></i>
          </div>
          <div class="panel-footer"></div>
        </div>
        <div class="panel pie"  style="height: 5rem;">
          <h2>动态数字治疗</h2>
          <div style="width: 100%; height: 0.2rem;"></div>
          <div style="width: 100%; height: 0.64rem;">
            <div style="float: left;  font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 5%; position: relative;">健康数据上传总次数</div>
            <div style="float: right;  font-size: 0.21rem; color: rgba(8, 248, 0, 0.904); line-height: 0.35rem; right: 3%; position: relative;">200次</div>
            <!-- <el-progress :text-inside="false" :show-text="false" :stroke-width="26" :percentage="70"  style="float: right; width: 50%; right: 6.5%;"></el-progress> -->
            <img src="./images/06.png" style="width: 3.2rem; top: 0.05rem; right: 0.5rem; float: right; position: relative;">
          </div>
          <div style="width: 100%; height: 0.64rem;">
            <div style="float: left;  font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 5%; position: relative;">智慧医疗设备总台数</div>
            <div style="float: right;  font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; right: 3%; position: relative;">300台</div>
            <!-- <el-progress :text-inside="false" :show-text="false" :stroke-width="24" :percentage="75" status="success" style="float: right; width: 50%; right: 6.5%;"></el-progress> -->
            <img src="./images/04.png" style="width: 3.2rem; top: 0.05rem; right: 0.5rem; float: right; position: relative;">
          </div>
          <div style="width: 100%; height: 0.64rem;">
            <div style="float: left;  font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 5%; position: relative;">药品总盒数</div>
            <div style="float: right;  font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; right: 3%; position: relative;">288盒</div>
            <!-- <el-progress :text-inside="false" :show-text="false" :stroke-width="22" :percentage="70" status="warning" style="float: right; width: 50%; right: 6.5%;"></el-progress> -->
            <img src="./images/05.png" style="width: 3.2rem; top: 0.05rem; right: 0.5rem; float: right; position: relative;">
          </div>
          <div style="width: 100%; height: 0.64rem;">
            <div style="float: left;  font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 5%; position: relative;">生活干预总次数</div>
            <div style="float: right;  font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; right: 3%; position: relative;">180次</div>
            <!-- <el-progress :text-inside="false" :show-text="false" :stroke-width="20" :percentage="75" status="exception" style="float: right; width: 50%; right: 6.5%;"></el-progress> -->
            <img src="./images/07.png" style="width: 3.2rem; top: 0.05rem; right: 0.5rem; float: right; position: relative;">
          </div>
          <div style="top: 0.1rem; position: relative;">
            <img src="./images/001.png" style="width: 2.5rem; left: 0.7rem; position: relative;">
            <img src="./images/002.png" style="width: 2.5rem; left: 1.4rem; position: relative;">
            <!-- <img src="./images/001.png" style="width: 35%; left: 10%; position: relative;">
            <img src="./images/002.png" style="width: 35%; left: 20%; position: relative;"> -->
            <div style="bottom: 1rem; left: 1.5rem; position: relative; width: 1.6rem; height: 0.8rem;">
              <div style="float: left;  font-size: 0.2rem; color: rgb(255, 255, 255); line-height: 0.35rem; left: 0.05rem; top: 0.05rem; position: relative;">物理干预总次数</div>
              <div style="float: left;  font-size: 0.25rem; color: rgb(75, 195, 251); line-height: 0.35rem;left: 0.5rem; top: 0.05rem; position: relative;">88次</div>
            </div>
            <div style="bottom: 1.8rem; left: 4.5rem; position: relative; width: 1.6rem; height: 0.8rem;">
              <div style="float: left;  font-size: 0.2rem; color: rgb(255, 255, 255); line-height: 0.35rem; left: 0.2rem; top: 0.05rem; position: relative;">生物干预总次数</div>
              <div style="float: left;  font-size: 0.25rem; color: rgb(75, 195, 251); line-height: 0.35rem;left: 0.6rem; top: 0.05rem; position: relative;">66次</div>
            </div>
          </div>
          <div class="panel-footer"></div>
        </div>
      </div>
      <div class="column">
        <div class="panel pie1" style="position: relative; height: 10.36rem;">

          <h2>用户人群分析</h2>
          <div style="position: relative; top: 0.5rem;">
            <div class="map">
            <div class="chart"></div>
            <div class="map1"></div>
            <div class="map2"></div>
            <div class="map3"></div>
          </div>
          <div style="bottom: 2.2rem; left: 1rem; position: relative; width: 2rem; height: 0.6rem; background: rgba(101, 132, 226, 0.1);">
            <div style="float: left;  font-size: 0.15rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left:0.2rem; top: 0.05rem; position: relative;">本年新增</div>
            <div style="float: left;  font-size: 0.21rem; color: rgb(75, 195, 251); line-height: 0.35rem; left:0.4rem; top: 0.17rem;  position: relative;">+3000人</div>
          </div>
          <div style="bottom: 2rem; left: 5rem; position: relative; width: 2rem; height: 0.6rem; background: rgba(101, 132, 226, 0.1);">
            <div style="float: left;  font-size: 0.21rem; color: rgb(75, 195, 251); line-height: 0.35rem; left:0.2rem; top: 0.17rem;  position: relative;">+100人</div>
            <div style="float: left;  font-size: 0.15rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left:0.4rem; top: 0.05rem; position: relative;">今日新增</div>
          </div>
          <div style="bottom: 2.2rem; left: 0.4rem; position: relative; width: 2rem; height: 0.6rem; background: rgba(101, 132, 226, 0.1);">
            <div style="float: left;  font-size: 0.15rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left:0.2rem; top: 0.05rem; position: relative;">本月新增</div>
            <div style="float: left;  font-size: 0.21rem; color: rgb(75, 195, 251); line-height: 0.35rem; left:0.4rem; top: 0.17rem;  position: relative;">+1000人</div>
          </div>
          <div class="no" style="left: 20%; position: relative; bottom: 1.8rem;">
            <div class="no-bd" style="width: 90%; left: 5%; bottom: 0.2rem; position: relative;">
              <ul>
                <li>用户总人数</li>
                <li>最新一周新增</li>
              </ul>
            </div>
            <div class="no-hd" style="width: 90%; left: 5%; position: relative;">
              <ul>
                <li style="color: rgb(144, 211, 254);">12581</li>
                <li style="color: rgb(7, 213, 125);">456</li>
              </ul>
            </div>
          </div>
          </div>

          <div style="position: relative; bottom: 1rem;" class="tableAreaPage">
            <h2>新注册会员信息</h2>
            <el-table
              :data="tableData" border
              height="4rem"
              class="elTable"
              ref="table"
              style="background-color: rgb(17, 86, 150);
              border:0.02rem solid rgb(17, 86, 150);
              border-color: rgb(17, 86, 150)"
              :header-cell-style="{background:'rgb(13, 52, 129)',color:'rgb(255, 255, 255)'}"
              >
              <el-table-column prop="name" label="用户姓名" min-width="23%"></el-table-column>
              <el-table-column prop="type" label="会员属性" min-width="23%" ></el-table-column>
              <el-table-column prop="age" label="会员年龄" min-width="23%" ></el-table-column>
              <el-table-column prop="mobile" label="会员号码" min-width="31%"></el-table-column>
            </el-table>
          </div>

          <div class="panel-footer"></div>

        </div>
      </div>
      <div class="column">
        <div class="panel bar1" style="height: 4.18rem;">
          <h2 style="{color: #83bff6}">会员数量</h2>
          <div id="lineChart1" class="chart"></div>
          <div class="panel-footer"></div>
        </div>
        <div class="panel line1" style="height: 6rem;">
          <h2>家庭医生服务</h2>
          <div style="width: 100%; height: 0.5rem;"></div>
          <div>
            <div style="float: left; width: 50%;">
              <img src="./images/003.png" style="float: left; width: 0.7rem; left: 0.4rem; position: relative;">
              <!-- <img src="./images/003.png" style="float: left; width: 20%; left: 13%; position: relative;"> -->
              <div style="font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 0.6rem; position: relative;">家庭医生总人数</div>
              <div style="font-size: 0.26rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 0.6rem; top: 0.04rem; position: relative;">26（人）</div>
            </div>
            <div style="float: left; width: 40%;">
              <img src="./images/004.png" style="float: left; width: 0.7rem; position: relative;">
              <div style="font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 5%; position: relative;">健康管理师总人数</div>
              <div style="font-size: 0.26rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 5%; top: 0.04rem; position: relative;">48（人）</div>
            </div>
          </div>
          <div style="width: 100%; height: 1.5rem;"></div>
          <div>
            <div style="float: left; width: 50%;">
              <img src="./images/005.png" style="float: left; width: 0.7rem; left: 0.4rem; position: relative;">
              <!-- <img src="./images/003.png" style="float: left; width: 20%; left: 13%; position: relative;"> -->
              <div style="font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 0.6rem; position: relative;">健康档案建立人次</div>
              <div style="font-size: 0.26rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 0.6rem; top: 0.04rem; position: relative;">102（人）</div>
            </div>
            <div style="float: left; width: 40%;">
              <img src="./images/006.png" style="float: left; width: 0.7rem; position: relative;">
              <div style="font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 5%; position: relative;">动态方案调整人数</div>
              <div style="font-size: 0.26rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 5%; top: 0.04rem; position: relative;">128（人）</div>
            </div>
          </div>
          <div style="width: 100%; height: 1.5rem;"></div>
          <div>
            <div style="float: left; width: 50%;">
              <img src="./images/005.png" style="float: left; width: 0.7rem; left: 0.4rem; position: relative;">
              <!-- <img src="./images/003.png" style="float: left; width: 20%; left: 13%; position: relative;"> -->
              <div style="font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 0.6rem; position: relative;">疾病风险评估次数</div>
              <div style="font-size: 0.26rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 0.6rem; top: 0.04rem; position: relative;">362（人）</div>
            </div>
            <div style="float: left; width: 40%;">
              <img src="./images/006.png" style="float: left; width: 0.7rem; position: relative;">
              <div style="font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 5%; position: relative;">健康咨询总人数</div>
              <div style="font-size: 0.26rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 5%; top: 0.04rem; position: relative;">688（人）</div>
            </div>
          </div>
          <!-- <div>
            <div style="float: left;">
              <img src="./images/005.png" style="float: left; width: 20%; left: 13%; position: relative;">
              <div style="font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 19%; position: relative;">健康档案建立人次</div>
              <div style="font-size: 0.26rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 19%; top: 5px; position: relative;">102（人）</div>
            </div>
            <div>
              <img src="./images/006.png" style="float: left; width: 11%; left: 2%; position: relative;">
              <div style="font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 6%; position: relative;">动态方案调整人数</div>
              <div style="font-size: 0.26rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 6%; top: 5px; position: relative;">128（人）</div>
            </div>
          </div>
          <div style="width: 100%; height: 30px;"></div>
          <div style="width: 100%; height: 30px;"></div>
          <div>
            <div style="float: left;">
              <img src="./images/005.png" style="float: left; width: 20%; left: 13%; position: relative;">
              <div style="font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 19%; position: relative;">疾病风险评估次数</div>
              <div style="font-size: 0.26rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 19%; top: 5px; position: relative;">362（人）</div>
            </div>
            <div>
              <img src="./images/006.png" style="float: left; width: 11%; left: 2%; position: relative;">
              <div style="font-size: 0.21rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 6%; position: relative;">健康咨询总人数</div>
              <div style="font-size: 0.26rem; color: rgba(255, 255, 255, 0.7); line-height: 0.35rem; left: 6%; top: 5px; position: relative;">688（人）</div>
            </div>
          </div> -->
          <div class="panel-footer"></div>
        </div>
      </div>
      <div class="column">
      </div>
    </section>
  </body>
</template>

<script>
import { getAreaPage } from '../../api/login'
import './js/flexible'
export default {
  data () {
    return {
      requestHeadUrl: 'https://dbadmin.dbzdjk.com',
      imgurl: 'https://health-planet.oss-cn-shanghai.aliyuncs.com/report/2023-10-25/1a70eab03b75c84e9b7913b89ab291444704.jpg',
      count: 0,
      loading: false,
      scrolltimer: '',
      channel: window.localStorage.getItem('channel'),
      list:[],
      sum: 0,
      tableData: [
       {
         name: '王**',
         type: '普通会员',
         age: '38岁',
         mobile: '189627******'
       },
       {
         name: '张**',
         type: '普通会员',
         age: '42岁',
         mobile: '136528******'
       },
       {
         name: '李**',
         type: 'VIP会员',
         age: '40岁',
         mobile: '156136******'
       },
       {
         name: '赵**',
         type: '全年会员',
         age: '40岁',
         mobile: '162136******'
       },
       {
         name: '倪**',
         type: 'VIP会员',
         age: '45岁',
         mobile: '135874******'
       },
       {
         name: '严**',
         type: '普通会员',
         age: '43岁',
         mobile: '184125******'
       },
       {
         name: '仲**',
         type: '全年会员',
         age: '42岁',
         mobile: '137738******'
       },
       {
         name: '刘**',
         type: '基础会员',
         age: '52岁',
         mobile: '189627******'
       },
       {
         name: '赵**',
         type: '全年会员',
         age: '40岁',
         mobile: '162136******'
       },
       {
         name: '王**',
         type: '普通会员',
         age: '38岁',
         mobile: '189627******'
       },
       {
         name: '仲**',
         type: '全年会员',
         age: '42岁',
         mobile: '137738******'
       },
       {
         name: '王**',
         type: '普通会员',
         age: '38岁',
         mobile: '189627******'
       },
       {
         name: '张**',
         type: '普通会员',
         age: '42岁',
         mobile: '136528******'
       },
       {
         name: '李**',
         type: 'VIP会员',
         age: '40岁',
         mobile: '156136******'
       },
       {
         name: '赵**',
         type: '全年会员',
         age: '40岁',
         mobile: '162136******'
       },
       {
         name: '倪**',
         type: 'VIP会员',
         age: '45岁',
         mobile: '135874******'
       },
      ]
    }
  },
  created () {
    const thisObj = this
    thisObj.onLoad()
    let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'js/flexible.js';
        document.body.appendChild(script);
        script.src = 'https://unpkg.com/element-ui/lib/index.js';
        document.body.appendChild(script);
  },
  mounted() {
    // var browerWidth = window.innerWidth; //浏览器可视宽度
    // var baseWidth = 1920; //设计稿宽度
    // var zoomValue = browerWidth / baseWidth; //缩放比例计算
    // document.getElementById("bodytest").style.transform = "scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id
    // window.onresize = function () { //窗口尺寸变化时，重新计算和缩放
    //   browerWidth = window.innerWidth;
    //   zoomValue = browerWidth / baseWidth;
    //   document.getElementById("bodytest").style.transform = "scale(" + zoomValue + "," + zoomValue + ")";
    // }
    const table = this.$refs.table// 拿到表格挂载后的真实DOM
    const divData = table.bodyWrapper// 拿到表格中承载数据的div元素
    setInterval(() => {
      // console.log('divData.clientHeight = ' + divData.clientHeight + 'divData.scrollTop = ' + Math.round(divData.scrollTop) + 'divData.scrollHeight = ' + divData.scrollHeight)
      divData.scrollTop += 2// 元素自增距离顶部1像素
      if (divData.clientHeight + Math.round(divData.scrollTop) == divData.scrollHeight) {
        divData.scrollTop = 0// 重置table距离顶部距离
      }
    }, 100)
  },
  methods: {
    load () {
        this.count += 2
    },
    drawLine (list) {
      const myChart = this.$echarts.init(document.getElementById('lineChart'))
      // 绘制图表
      myChart.setOption({
        title: {
          text: '会员数量',
          textStyle: {
            color: '#83bff6'
          },
          subtext: '单位:人'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['人数'],
        },
        xAxis: {
          data: ["初级会员","基础会员","全年会员","VIP会员"],
          axisLabel: {
            color: 'white'
          },
        },
        yAxis: {
          type: 'value',
          min: 0,
          axisLabel: {
            formatter: '{value} 人',
            color: 'white'
          }
        },
        series: [
          {
            name: '人数',
            type: 'bar',
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'top',
                  textStyle: {
                    color: 'white',
                     fontSize: 16
                  }
                }
              }
            },
            emphasis: {
              itemStyle: {
                color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#2378f7' },
                  { offset: 0.7, color: '#2378f7' },
                  { offset: 1, color: '#83bff6' }
                ])
              }
            },
            data: [list.experienceUser, list.basicUser, list.annualUser, list.vipUser],
            barWidth: '25%',
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#83bff6' },
              { offset: 0.5, color: '#188df0' },
              { offset: 1, color: '#188df0' }
            ]),
          }
        ]
      })
    },

  test1(list) {
  const myChart = this.$echarts.init(document.getElementById('lineChart1'))
  // 指定配置和数据
  var option = {
    color: ["#2f89cf"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    grid: {
      left: "0%",
      top: "10%",
      right: "0%",
      bottom: "4%",
      containLabel: true
    },
    xAxis: [
      {
        type: "category",
        data: ["初级会员","基础会员","全年会员","VIP会员","普通用户"],
        // data: ["初级会员","基础会员","全年会员","VIP会员"],
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          textStyle: {
            color: "rgba(255,255,255,.6)",
            fontSize: "12"
          }
        },
        axisLine: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        type: "value",
        min: 0,
        axisLabel: {
          formatter: '{value} 人',
          color: "rgba(255,255,255,.6)",
          fontSize: "12"
        },
        axisLine: {
          lineStyle: {
            color: "rgba(255,255,255,.1)"
          }
        },
        splitLine: {
          lineStyle: {
            color: "rgba(255,255,255,.1)"
          }
        }
      }
    ],
    series: [
      {
        barWidth: "35%",
        name: '人数',
        type: 'bar',
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: 'white',
                  fontSize: 16
              }
            }
          }
        },
        data: [list.annualUser, list.basicUser, list.experienceUser, list.vipUser, list.vipUser],
      }
    ]
  };

  // 把配置给实例对象
  // option.series[0].data = [list.annualUser?list.annualUser+140:140, list.basicUser?list.basicUser+100:100, list.experienceUser?list.experienceUser+60:60, list.vipUser?list.vipUser+40:40, list.ordinaryUser?list.ordinaryUser+30:30];
  // option.series[0].data = [list.annualUser?list.annualUser+110:110, list.basicUser?list.basicUser+80:80, list.experienceUser?list.experienceUser+40:40, list.vipUser?list.vipUser+20:20, list.ordinaryUser?list.ordinaryUser+30:30];
  option.series[0].data = [list.annualUser, list.basicUser, list.experienceUser, list.vipUser, list.ordinaryUser];
  // option.series[0].data = [list.annualUser, list.basicUser, list.experienceUser, list.vipUser, list.ordinaryUser];
  myChart.setOption(option);
  window.addEventListener("resize", function() {
    myChart.resize();
  });
},

    onLoad: function () {
      // let List ={
      //   'annualUser': 5,
      //   'basicUser': 1,
      //   'experienceUser': 2,
      //   'ordinaryUser': 338,
      //   'vipUser': 21,
      // }
      // console.log('test List')
      // console.log(List)
      // console.log(List.annualUser)
      // this.test1(List)
      // console.log("username : " + window.localStorage.getItem('username'))
      getAreaPage(this.channel).then(res => {
        // console.log('res')
        // console.log(res)
        // console.log(this.channel)
        // // this.drawLine(res.data)
        // console.log(res.data)
        this.list = res.data
        let annualUser = res.data.annualUser?res.data.annualUser:0
        let basicUser = res.data.basicUser?res.data.basicUser:0
        let experienceUser = res.data.experienceUser?res.data.experienceUser:0
        let vipUser = res.data.vipUser?res.data.vipUser:0
        let ordinaryUser = res.data.ordinaryUser?res.data.ordinaryUser:0
        // console.log(window.localStorage.getItem('channel') == '大白主动健康')
        // console.log(res.data)
        if(window.localStorage.getItem('channel') == '大白主动健康'){
          this.list.annualUser = annualUser + 110
          // console.log('test annualUser')
          // console.log(annualUser )
          // console.log(this.list.annualUser )
          this.list.basicUser = basicUser + 80
          this.list.experienceUser = experienceUser + 40
          this.list.vipUser = vipUser + 20
          this.list.ordinaryUser = ordinaryUser + 30
          this.sum = annualUser + basicUser + experienceUser + vipUser + ordinaryUser + 280
        }else{
          this.list.annualUser = annualUser
          this.list.basicUser = basicUser
          this.list.experienceUser = experienceUser
          this.list.vipUser = vipUser
          this.list.ordinaryUser = ordinaryUser
          this.sum = annualUser + basicUser + experienceUser + vipUser + ordinaryUser
        }
        // console.log(this.list)
        // console.log('test sum')
        // console.log(this.sum)
        // this.test1(res.data)
        this.test1(this.list)
        // this.getData()
      })
    }
  }
}
</script>

<style>
/* @import url(https://unpkg.com/element-ui/lib/theme-chalk/index.css); */
@import url(./css/index.css);
    .tableAreaPage col[name="gutter"] {
        /* display: table-cell !important;
         */
         /* display: none; */
         width: 0px;
    }

    .tableAreaPage .elTable{
      border: 0.02rem solid rgb(11, 64, 122);
    }
    .tableAreaPage .el-table__body-wrapper::-webkit-scrollbar {
      width: 0px;
      /* background-color: rgb(17, 86, 150); */
    }
    .tableAreaPage .el-table__header-wrapper table,.el-table__body-wrapper table{
      width: 100%;
    }
    /* .el-table__body, .el-table__footer, .el-table__header{
      table-layout: auto;
    } */
    .tableAreaPage .el-table .el-table__cell {
      background-color: rgb(11, 64, 122);
      color: rgb(255, 255, 255);
    }

    .tableAreaPage th.gutter {
      display: initial;
    }

    .tableAreaPage .el-table .el-table__cell.gutter {
      background-color: rgb(17, 86, 150);
      width: 100%;
    }

    .tableAreaPage .el-table thead tr {
      background-color: rgb(17, 86, 150);
      width: 100%;
    }

    /* .el-table {
      border-bottom: 1px solid rgb(17, 85, 147);
      border-right: 1px solid rgb(17, 85, 147);
      margin: 0 auto;
    } */

    .tableAreaPage .el-table {
      border-bottom: 1px solid rgb(11, 64, 122);
      border-right: 1px solid rgb(11, 64, 122);
      margin: 0 auto;
    }

    .tableAreaPage .el-table th {
      border: 1px solid rgb(17, 85, 147);
      border-right: none;
      border-bottom: none;
    }

    .tableAreaPage .el-table td {
      border: 1px solid rgb(11, 64, 122);
      border-right: none;
    }

    /* .el-table--border, .el-table--group {
        border: 1px solid rgb(17, 85, 147)!important;
    }
    .el-table--border  td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
        border-right: 1px solid rgb(17, 85, 147)!important;
    }
    .el-table  td, .el-table th.is-leaf  {
        border-bottom: 1px solid rgb(17, 85, 147)!important;
    }
    .el-table thead tr th{
        border-color: rgb(17, 85, 147);
    } */

    .tableAreaPage .el-table--border, .el-table--group {
        border: 1px solid rgb(11, 64, 122);
    }
    .tableAreaPage .el-table--border  td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
        border-right: 1px solid rgb(11, 64, 122);
    }
    .tableAreaPage .el-table  td, .el-table th.is-leaf  {
        border-bottom: 1px solid rgb(11, 64, 122);
    }
    .tableAreaPage .el-table thead tr th{
        border-color: rgb(11, 64, 122);
    }

    .tableAreaPage .el-table td .el-table th.is-leaf .el-table--border .el-table--group {
      border-color: #409eff;
    }
    .tableAreaPage .scroll {
      height: 270px;
      width: 500px;
      margin: 100px auto;
      overflow: hidden;
    }
    .tableAreaPage .scroll .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 3px 0;
    }
    /* .el-table__body-wrapper {
            height: 200px;
            border-right: none;
            overflow-y: scroll;
        }
        .el-table__body-wrapper::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background-color: #bfcbd9;
            border-radius: 3px;
        } */

  /* .el-table__body-wrapper::-webkit-scrollbar {
    width: 8px;
    height: 12px;
  }
  .el-table__body-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #eeeeee;
  }
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #409eff;
  } */

  </style>
